var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filter',{attrs:{"fields":_vm.headers,"busy":busy,"add-id-filter":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"headers":_vm.headers,"items":items,"filters":_vm.filters,"row-select":"","row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.promotionType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('promotion_type.' + item.promotionType))+" ")]}},{key:"item.promotionTarget",fn:function(ref){
var item = ref.item;
return [(item.promotionType === _vm.promotionType.user)?_c('span',[_vm._l((item.users.slice(0, 3)),function(user,index){return _c('v-chip',{key:("promotion-" + index),attrs:{"color":"blue lighten-5"}},[_vm._v(" "+_vm._s(user.email)+" ")])}),(item.users.length > 3)?_c('span',[_vm._v(" ... ")]):_vm._e()],2):_vm._e(),(item.promotionType === _vm.promotionType.company)?_c('span',[_c('v-chip',{attrs:{"color":"light-green lighten-5"}},[_vm._v(" "+_vm._s(item.company.name)+" ")])],1):_vm._e(),(
                            item.promotionType === _vm.promotionType.allowance
                        )?_c('span',[_vm._l((item.allowances.slice(0, 3)),function(allowance,index){return _c('v-chip',{key:("allowance-" + index),attrs:{"color":"amber lighten-5"}},[_vm._v(" "+_vm._s(allowance.name)+" ")])}),(item.allowances.length > 3)?_c('span',[_vm._v(" ... ")]):_vm._e()],2):_vm._e()]}},{key:"item.discountType",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('promotion_discount_type.' + item.discountType))+" ")]}},{key:"rowActions",fn:function(ref){
                        var item = ref.item;
return [_c('edit-button',{on:{"execute":function($event){return _vm.$router.push({
                                name: _vm.routeType.PROMOTION_EDIT,
                                params: { id: item.id },
                            })}}}),_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}},{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }