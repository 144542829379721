var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"headers":_vm.headers,"items":items,"filters":_vm.filters,"row-select":"","row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.appliesToDelivery",fn:function(ref){
var item = ref.item;
return [(item.appliesToDelivery)?[_c('v-chip',{attrs:{"label":"","color":"green","text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.$t('labels.yes'))+" ")])]:[_c('v-chip',{attrs:{"label":"","color":"red","text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.$t('labels.no'))+" ")])]]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.isPercent)?[_vm._v(" "+_vm._s(parseFloat(item.value))+"% ")]:[_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]]}},{key:"item.restaurant",fn:function(ref){
var item = ref.item;
return _vm._l((item.restaurant),function(restaurant,i){return _c('span',{key:i,staticClass:"restaurent_item"},[_vm._v(" "+_vm._s(restaurant.name)+" ")])})}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('edit-button',{on:{"execute":function($event){return _vm.$router.push({
                                name: _vm.routeType.COUPON_EDIT,
                                params: { id: item.id },
                            })}}}),_c('delete-action',{attrs:{"entity-name":item.name,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}},{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }