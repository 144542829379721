<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :headers="headers"
                    :items="items"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <template v-slot:[`item.appliesToDelivery`]="{ item }">
                        <template v-if="item.appliesToDelivery">
                            <v-chip
                                label
                                color="green"
                                text-color="white"
                                small
                            >
                                {{ $t('labels.yes') }}
                            </v-chip>
                        </template>
                        <template v-else>
                            <v-chip label color="red" text-color="white" small>
                                {{ $t('labels.no') }}
                            </v-chip>
                        </template>
                    </template>
                    <template v-slot:[`item.value`]="{ item }">
                        <template v-if="item.isPercent">
                            {{ parseFloat(item.value) }}%
                        </template>
                        <template v-else>
                            {{ item.value | currency() }}
                        </template>
                    </template>
                    <template v-slot:[`item.restaurant`]="{ item }">
                        <span
                            v-for="(restaurant, i) in item.restaurant"
                            :key="i"
                            class="restaurent_item"
                        >
                            {{ restaurant.name }}
                        </span>
                    </template>
                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button
                            @execute="
                                $router.push({
                                    name: routeType.COUPON_EDIT,
                                    params: { id: item.id },
                                })
                            "
                        ></edit-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>

            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import { mapGetters } from 'vuex'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from '@/pages/coupons/mixins/Headers'
import configType from '@/store/type/configType'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, Headers],
    data() {
        return {
            api: RestApiType.COUPONS,
        }
    },
    computed: {
        ...mapGetters({
            currencyCode: configType.getters.CURRENCY_CODE,
        }),
        tableName() {
            return this.$t('labels.coupons')
        },
    },
}
</script>
<style lang="scss">
.restaurent_item + .restaurent_item:before {
    content: ',';
}
</style>
